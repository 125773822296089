<template>
  <NuxtLayout>
    <NuxtLoadingIndicator color="repeating-linear-gradient(to right,#f5c542 0%,#f3ba1f 50%,#ffba00 100%)" />
    <NuxtPage />
  </NuxtLayout>
</template>
<script lang="ts" setup>
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import gsap from 'gsap'
// Fetch CONFIG DATA
// TODO 性能优化
const route = useRoute()
if (!route.path.includes('/manage/healthz')) {
  const { config } = storeToRefs(useStore())
  const { fetchConfig, updateSEO } = useStore()
  await fetchConfig() // 请求公共配置信息
  // 重定向到默认语言
  const redirectLang = () => {
    if (config.value.region_lang && config.value.region_lang !== route.params.lang) {
      if (route.params.lang === '{lang}') {
        navigateTo(`${decodeURIComponent(route.path).replace('{lang}', config.value.region_lang)}`)
      } else {
        navigateTo(`/${config.value.region_lang}/`)
      }
    }
  }
  redirectLang()
  // COMMON SEO CONFIG
  updateSEO() // default seo config
  // gsap.registerPlugin(ScrollTrigger)
}
</script>
<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: all 0.2s ease;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  //filter: blur(0.5rem);
}
</style>
