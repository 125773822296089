import { defineComponent } from 'vue'
import { ClientOnly } from '#components'
export default defineComponent({
  name: 'MarkdownBlockly',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const blocklyRef = ref<HTMLElement>()
    const blockly = ref<any>()
    const route = useRoute()
    onMounted(async () => {
      await nextTick()
      try {
        const { $blockly } = useNuxtApp()
        blocklyRef.value &&
          (blockly.value = $blockly(blocklyRef.value, props.data, (route.params.lang as string) || 'en'))
      } catch (error) {
        console.error(error)
      }
    })
    onUnmounted(() => {
      if (blockly.value) {
        blockly.value.dispose()
      }
    })
    return () => (
      <ClientOnly>
        <div class={'app-markdown-blockly-inner'}>
          <div ref={blocklyRef} class={'app-markdown-blockly'}></div>
        </div>
      </ClientOnly>
    )
  }
})
