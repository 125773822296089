import markdownit from 'markdown-it'
import hljs from 'highlight.js'
import markdownAnchor from 'markdown-it-anchor'
// import slugify from '@sindresorhus/slugify'
import 'highlight.js/styles/github-dark.css'
const markdown: any = markdownit({
  html: true,
  xhtmlOut: false,
  linkify: true,
  langPrefix: 'language-',
  typographer: true,
  quotes: '“”‘’',
  breaks: true,
  highlight: function (str: string, lang: string) {
    const className = `language-${lang}`
    if (lang && hljs.getLanguage(lang)) {
      try {
        return (
          '<pre class=' +
          className +
          '><code class="hljs">' +
          hljs.highlight(str, { language: lang, ignoreIllegals: true }).value +
          '</code>' +
          '<small class="hljs-tag">' +
          lang +
          '</small>' +
          '</pre>'
        )
      } catch (__) {}
    }
    return `<pre><code class="${className}">${markdown.utils.escapeHtml(str)}</code></pre>`
  }
}).use(markdownAnchor, {
  level: [1, 2, 3],
  permalink: markdownAnchor.permalink.headerLink(),
  slugify: (text: string) => {
    // TODO unique id supported in the future
    return text
      .toLowerCase()
      .replace(/[!@$%^&*()+=<>#?:"{}|,.;'`~]/g, '')
      .trim()
      .replace(/^(\d)/g, '_' + '$1')
      .replace(/\s+/g, '-')
  }

  // slugify(text.match(/^(\d)/g) ? `_${text}` : text, {
  //   preserveTrailingDash: true,
  //   preserveLeadingUnderscore: true,
  //   customReplacements: [['&', '']]
  // })
})

export default defineNuxtPlugin((_nuxtApp) => {
  return {
    provide: {
      markdown
    }
  }
})
